import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./PrivacyAgreement.scss";
import { getStoreList } from "@service/api";

interface State {
	key: any;
	storeList: any;
}
interface mineProps {}
interface DispatchProps {}
type Props = DispatchProps & RouteComponentProps & mineProps;

class PrivacyAgreement extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			key: "",
			storeList: [],
		};
	}

	componentDidMount() {
		this.updateDocumentTitle();
		this.updateStoreList();
		this.setState({
			key: Date.now(),
		});
	}

	componentDidUpdate() {
		this.updateDocumentTitle();
	}

	updateDocumentTitle() {
		document.title = "隐私协议";
	}

	updateStoreList() {
		getStoreList().then((res) => {
			let list: any = [];
			res.forEach((ele) => {
				list.push(ele.store_name);
			});
			this.setState({
				storeList: list,
			});
		});
	}

	linkToThirdPartyList() {
		const { history } = this.props;
		const path = `/user/third-party-list`;
		history.push(path);
	}

	render() {
		return (
			<div className="privacyAgreement">
				<div className="title">生效时间：【2024】年【6】月【13】日</div>
				<div className="title">最近更新时间：【2024】年【6】月【13】日</div>
				<div className="content">
					脱单实验室RIGHT（以下简称“我们”）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：
					<strong>
						权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则
					</strong>
					等等。
				</div>
				<div className="content">
					<strong>
						同时我们承诺，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保障您的个人信息安全可控。
					</strong>
					鉴于此，我们制定本《脱单实验室RIGHT隐私保护政策》（下称“本政策
					/本隐私保护政策”）并提醒您：
				</div>
				<div className="content">
					本政策适用于脱单实验室RIGHT产品或服务。若我们关联公司的产品或服务中使用了脱单实验室RIGHT提供的产品或服务（例如直接使用脱单实验室RIGHT账户登录）但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。
				</div>
				<div className="content">
					需要特别说明的是，除本隐私政策说明的相关信息收集使用情况外，本政策不适用于其他第三方向您提供的服务，也不适用于脱单实验室RIGHT中已另行独立设置隐私权政策的产品或服务。
				</div>
				<div className="content">
					在使用脱单实验室RIGHT各项产品或服务前，请您务必仔细阅读并透彻理解本政策，重点内容我们已采用粗体的方式来表示，望您特别注意，在确认充分理解并同意后使用我们的相关产品或服务。一旦您开始使用脱单实验室RIGHT各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过脱单实验室RIGHT提供的各种联系方式与我们联系。我们也会基于法律法规及业务发展变化，不时对本隐私政策进行修订，并以适当方式告知您。
				</div>
				<div>本隐私权政策部分将帮助您了解以下内容：</div>
				<div>1.我们如何收集和使用您的个人信息</div>
				<div>2.我们如何使用 Cookie 和同类技术</div>
				<div>3.我们如何共享、转让、公开披露您的个人信息</div>
				<div>4.我们如何保护您的个人信息</div>
				<div>5.您如何管理您的个人信息</div>
				<div>6.您的个人信息如何在全球范围转移</div>
				<div>7.本隐私权政策如何更新</div>
				<div>8.如何联系我们</div>
				<div className="title">一、我们如何收集和使用您的个人信息</div>
				<div>
					个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。我们会出于本政策所述的以下目的，收集和使用您的个人信息：{" "}
				</div>
				<div>（一）帮助您成为我们的用户</div>
				<div>
					1.
					当您注册时，为了完成创建账号，以便我们为您提供服务，您需要提供您的
					<strong>手机号码</strong>，我们会向您的
					<strong>手机号码</strong>发送短信验证码以验证您的身份。
				</div>
				<div>
					2.
					为了脱单实验室RIGHT的用户能够拥有更真实完善的社交资料，提升推荐的准确度，提高交友的效率，您还需要提供您的
					<strong>
						出生年份、性别、学历、职业、年收入、身高、现居地。
					</strong>
					上述信息系我们提供婚恋交友基本功能所必要的个人信息，收集这些信息是为了帮助您完成注册，建立您的征婚档案，提供基本的婚恋交友服务。若您不提供这类信息，您可能无法正常使用我们的产品或服务。
				</div>
				<div>
					3.
					在注册过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验：
					<strong>
						昵称、房产、车辆、个人照片、自我描述、理想对象
					</strong>
					等等。但如果您不提供这些信息，将不会影响使用脱单实验室RIGHT产品或服务的基本功能。
				</div>
				<div>
					请注意，
					<strong>
						您在本产品或服务上公开发布的个人信息，任何登录到我们产品或使用我们产品的第三人均可全部或部分的查看，以提高您通过我们产品相亲成功的机会。
					</strong>
					您提供这些信息即表示您同意我们不同程度地向我们产品的使用者披露。请注意，我们产品的任何用户（无论是否注册或登录）都可以访问、阅览您所提供并公开发布在产品上的全部或部分信息，对于他人使用您在我们产品上所提供的信息，我们不承担任何责任。
				</div>
				<div>
					请注意，我们产品的任何用户（无论是否注册）都可以访问您所提供的除真实姓名和个人联系方式、身份证以外的信息。对于他人使用或公开您在我们网站所提供的上述信息，我们不承担任何责任。
				</div>
				<div>（二）为您展示和推送商品或服务</div>
				<div>
					为改善我们的产品或服务、向您提供个性化的信息搜索及交易服务，我们会根据您的浏览及搜索记录、
					<strong>设备信息、位置信息、</strong>
					交易信息等，提取您的浏览、搜索偏好、行为习惯、
					<strong>位置信息</strong>
					等特征，基于特征标签进行间接人群画像并展示、推送信息。
				</div>
				<div>（三）与您位置相关的服务</div>
				<div>
					当您进行账号注册、发布动态、查看附近的人、附近动态，消息界面查看您与对方的距离时，我们会在获得您的自主选择同意开启位置权限后，记录您的
					<strong>地理位置</strong>
					信息。该信息属于个人敏感信息，拒绝提供该信息会使您无法使用上述功能，但不影响您正常使用脱单实验室RIGHT的其他功能。此外，您也可以随时关闭相关功能。
				</div>
				<div>（四）客户服务</div>
				<div>
					为向您提供客户服务，我们可能需要您提供必要的个人信息进行身份验证，以保证您的账号安全。当您与我们联系时，我们可能会保存您的通信/通话记录和内容、您留下的联系方式以及其他必要信息（包括
					<strong>账户信息、订单信息、</strong>
					您为了证明相关事实提供的其他信息），以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
				</div>
				<div>（五）我们通过间接获得方式收集到的您的个人信息</div>
				<div>
					您可通过脱单实验室RIGHT账号在我们提供的链接入口使用我们关联公司提供的产品或服务，为便于我们基于关联账号共同向您提供一站式服务并便于您统一进行管理，我们在脱单实验室RIGHT集中展示您的信息或推荐您感兴趣的信息。
				</div>
				<div>
					当您通过我们产品或服务使用上述服务时，您授权我们根据实际业务及合作需要从我们关联公司处接收、汇总、分析我们确认其来源合法或您授权同意其向我们提供的您的个人信息或交易信息。
				</div>
				<div>
					如您拒绝提供上述信息或拒绝授权，可能无法使用我们关联公司的相应产品或服务，或者无法展示相关信息，但不影响使用脱单实验室RIGHT核心服务。
				</div>
				<div>（六）运营及安全保障</div>
				<div>
					请注意，为确保用户身份真实性、向您提供更好的安全保障，您可以向我们提供身份证、社保卡、学历证明等身份信息完成实名认证。如您拒绝提供上述信息，可能无法使用账户管理、继续可能存在风险的交易等服务，但不会影响您使用浏览服务。{" "}
				</div>
				<div>
					为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或脱单实验室RIGHT相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志以及我们关联公司、授权公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
				</div>
				<div>
					设备信息：网络相关信息（WLAN接入点（如SSID、BSSID）、蓝牙、基站、IP地址、网络运营商、网络状态、类型、接入方式、网络质量数据）、软硬件操作系统信息（应用程序版本、操作系统、语言）等。
				</div>
				<div>
					日志信息：
					A.操作、使用的个人上网记录，包括浏览、搜索等；B.服务日志信息：Idiv地址、浏览器的类型、使用的语言、访问日期和时间及您访问的网页记录等。
				</div>
				<div>
					请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
				</div>
				<div>（七）第三方SDK及API技术</div>
				<div>
					部分功能和/或服务需要使用第三方
					SDK（或API）技术。请您知悉，这些第三方
					SDK（或API）可能会收集或使用您的部分个人信息（具体请见
					<span
						className="underline-link"
						onClick={() => this.linkToThirdPartyList()}
					>
						《第三方数据合作清单》
					</span>
					）。我们会对相关 SDK（或
					API）进行严格的安全检测，并采取技术手段，努力确保其按照本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。另外，对我们与之共享用户信息的公司、组织和个人，我们也会与其签署协议来规范对于用户信息的使用。（八）其他用途
				</div>
				<div>
					我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
				</div>
				<div>（九）征得授权同意的例外</div>
				<div>
					根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
				</div>
				<div>1.与国家安全、国防安全有关的；</div>
				<div>2.与公共安全、公共卫生、重大公共利益有关的；</div>
				<div>3.与犯罪侦查、起诉、审判和判决执行等有关的；</div>
				<div>
					4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
				</div>
				<div>5.在合理的范围内处理您自行公开的个人信息；</div>
				<div>
					6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
				</div>
				<div>7.根据您的要求签订合同所必需的；</div>
				<div>
					8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
				</div>
				<div>9.为合法的新闻报道所必需的；</div>
				<div>
					10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
				</div>
				<div>11.法律法规规定的其他情形。</div>
				<div>（十）反爬虫申明</div>
				<div>
					除非脱单实验室RIGHT另行声明，脱单实验室RIGHT内所有的用户资料（包括：昵称、个人基本信息、工作职业信息、自我介绍、相册图片等）的所有权利（包括肖像权、隐私权及其他相关权利）均归脱单实验室RIGHT及对应用户所有。未经平台或用户本人许可，任何人或机构不得以包括机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像等方式擅自使用平台内任何内容。
				</div>
				<div className="title">二、我们如何使用 Cookie 和同类技术</div>
				<div>
					Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie
					或匿名标识符（下称“Cookie”），以收集、标识您使用脱单实验室RIGHT服务时的信息。
				</div>
				<div>
					Cookie的使用主要是为了保障产品功能与服务的安全、高效运转，使我们确认您的账户与交易的安全状态，排查崩溃、延迟的相关异常情况；帮助您省去重复填写表单、输入搜索内容的步骤和流程，以获得更轻松的使用体验。我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。
				</div>
				<div>
					您可根据自己的偏好管理或删除Cookie。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置中操作清除Cookie数据，另外，您也可以清除软件内保存的所有Cookie。但清除后可能无法使用由我们提供的、依赖于Cookie的功能或服务。
				</div>
				<div className="title">
					三、我们如何共享、转让、公开披露您的个人信息
				</div>
				<div>（一）共享</div>
				<div>
					我们不会与脱单实验室RIGHT服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
				</div>
				<div>
					1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
				</div>
				<div>
					2.在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
				</div>
				<div>
					3.与关联公司间共享：为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护脱单实验室RIGHT关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息（如为便于您使用脱单实验室RIGHT账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
				</div>
				<div>
					4.与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供或由我们和授权合作伙伴共同提供。
					<strong>
						我们可能会与合作伙伴共享您的某些个人信息，详情可见
						<span
							className="underline-link"
							onClick={() => this.linkToThirdPartyList()}
						>
							《第三方数据合作清单》
						</span>
						，以提供更好的客户服务和用户体验。例如安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
					</strong>
				</div>
				<div>
					对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的《数据保护协定》，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。同时，我们会对合作伙伴进行严格地监督与管理，一旦发现其存在违规违约行为，将立即停止合作并追究其法律责任。
				</div>
				<div>（二）转让</div>
				<div>
					我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
				</div>
				<div>
					1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
				</div>
				<div>
					2.在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
				</div>
				<div>（三）公开披露</div>
				<div>我们仅会在以下情况下，公开披露您的个人信息：</div>
				<div>
					1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
				</div>
				<div>
					2.如果我们确定您出现违反法律法规或严重违反脱单实验室RIGHT相关协议规则的情况，或为保护脱单实验室RIGHT及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规在法律、法律程序、诉讼或政府主管部门强制性要求的情况下或脱单实验室RIGHT相关协议规则披露关于您的个人信息，包括相关违规行为以及脱单实验室RIGHT已对您采取的措施。
				</div>
				<div>
					（四）共享、转让、公开披露个人信息时事先征得授权同意的例外
				</div>
				<div>
					以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
				</div>
				<div>1.与国家安全、国防安全有关的；</div>
				<div>2.与公共安全、公共卫生、重大公共利益有关的；</div>
				<div>3.与犯罪侦查、起诉、审判和判决执行等有关的；</div>
				<div>
					4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				</div>
				<div>5.您自行向社会公众公开的个人信息；</div>
				<div>
					6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
				</div>
				<div>
					根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
				</div>
				<div>
					7.为实现使用目的，其他有必要的情况及法律法规规定的其他情形。
				</div>
				<div className="title">四、我们如何保护您的个人信息安全</div>
				<div>
					（一）我们已采取符合业界通用解决方案、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
				</div>
				<div>
					（二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
				</div>
				<div>
					（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
				</div>
				<div>
					（四）互联网并非法外之地，我们强烈建议您不要使用非脱单实验室RIGHT推荐的通信方式发送个人信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。
				</div>
				<div>
					如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络脱单实验室RIGHT客服，以便我们根据您的申请采取相应措施。
				</div>
				<div>
					请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在日记、话题等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
				</div>
				<div>
					请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
					同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
				</div>
				<div>
					但是，相信您也理解，无论采取何种严密措施，都难免发生信息泄漏的情况，就此类情况的发生，您同意免除我们相应的法律责任。
				</div>
				<div className="title">五、您如何管理您的个人信息</div>
				<div>您可以通过以下方式访问及管理您的个人信息：</div>
				<div>（一）访问您的个人信息</div>
				<div>
					您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
				</div>
				<div>
					账户信息
					——如果您希望访问或编辑您的账户中的个人证件信息，手机绑定、支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过登录账号点击我的-设置执行此类操作。
				</div>
				<div>
					日记信息
					——您可以登录账号“我的”访问或清除您的日记发表历史记录。
					如果您无法通过上述路径访问该等个人信息，您可以随时通过脱单实验室RIGHT客服与我们取得联系。我们将在30天内回复您的访问请求。
					对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（七）响应您的上述请求”中的相关安排向您提供。
				</div>
				<div>
					个人资料
					——如果您希望访问或编辑您个人资料中的昵称、头像、家乡、情感状态、出生年月日、星座、职业、学校、兴趣爱好以及地点足迹其他资料等，您可以通过登录编辑个人主页执行此类操作。-设置执行此类操作。
				</div>
				<div>（二）更正或补充您的个人信息</div>
				<div>
					当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
				</div>
				<div>（三）删除您的个人信息</div>
				<div>在以下情形中，您可以向我们提出删除个人信息的请求：</div>
				<div>1.如果我们处理个人信息的行为违反法律法规；</div>
				<div>
					2.如果我们收集、使用您的个人信息，却未征得您的明确同意；
				</div>
				<div>3.如果我们处理个人信息的行为严重违反了与您的约定；</div>
				<div>
					4.如果您不再使用我们的产品或服务，或您主动注销了账号；
				</div>
				<div>5.如果我们永久不再为您提供产品或服务。</div>
				<div>
					若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
				</div>
				<div>
					当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
				</div>
				<div>（四）改变您授权同意的范围</div>
				<div>
					每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以与脱单实验室RIGHT小编联系给予或收回您的授权同意。
				</div>
				<div>
					当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
				</div>
				<div>（五）个人信息主体注销账户</div>
				<div>您可以联系客服（微信：nana_lab）删除。</div>
				<div>
					在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。
				</div>
				<div>（六）约束信息系统自动决策</div>
				<div>
					在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害脱单实验室RIGHT商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
				</div>
				<div>（七）响应您的上述请求</div>
				<div>
					为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
					我们将在30天内作出答复。如您不满意，还可以通过脱单实验室RIGHT小编发起投诉。
				</div>
				<div>
					为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
					我们将在30天内作出答复。如您不满意，还可以通过脱单实验室RIGHT小编发起投诉。
				</div>
				<div>
					对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝，您不可因此向我们主张任何责任。
				</div>
				<div>
					在以下情形中，按照法律法规要求，我们将无法响应您的请求：
				</div>
				<div>1.与国家安全、国防安全有关的；</div>
				<div>2.与公共安全、公共卫生、重大公共利益有关的；</div>
				<div>3.与犯罪侦查、起诉、审判和执行判决等有关的；</div>
				<div>
					4.有充分证据表明个人信息主体存在主观恶意或滥用权利的；
				</div>
				<div>
					5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的;
				</div>
				<div>6.涉及商业秘密的；</div>
				<div>7.法律法规规定的其他情形；</div>
				<div className="title">六、您的个人信息如何在全球范围转移</div>
				<div>
					我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
				</div>
				<div>1.法律法规有明确规定；</div>
				<div>2.获得您的明确授权；</div>
				<div>3.您通过互联网进行跨境直播/发布动态等个人主动行为。</div>
				<div>
					针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。/发布动态等个人主动行为。
				</div>
				<div className="title">七、本隐私权政策如何更新</div>
				<div>
					我们的隐私权政策可能变更。未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括但不限于我们会通过脱单实验室RIGHT公示的方式进行通知甚至向您提供弹窗提示）。
				</div>
				<div>本政策所指的重大变更包括但不限于：</div>
				<div>
					1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
				</div>
				<div>
					2.我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
				</div>
				<div>3.个人信息共享、转让或公开披露的主要对象发生变化；</div>
				<div>
					4.您参与个人信息处理方面的权利及其行使方式发生重大变化；
				</div>
				<div>
					5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
				</div>
				<div>6.个人信息安全影响评估报告表明存在高风险时。</div>
				<div>我们还会将本隐私权政策的旧版本存档，供您查阅。</div>
				<div className="title">八、如何联系我们</div>
				<div>
					1.如对本政策内容有任何疑问、意见或建议，您可通过脱单实验室RIGHT客服与我们联系；
				</div>
				<div>
					2.如发现个人信息可能被泄露，您可以通过发送邮件至（acker@tuodanlab.com）投诉举报；
				</div>
				<div>
					如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
				</div>
			</div>
		);
	}
}

export default withRouter(PrivacyAgreement);
