import React, { Component } from 'react';
import {
	AppBar, Toolbar, Button
} from '@material-ui/core';
import {
	getUserInfoByCode,
	getCurrentUserInfo,
	customePrice,
	getWechatConfig
} from '@service/api';
import {
	List,
	InputItem,
	Switch,
	Toast
} from 'antd-mobile';
import './_custom_price.scss';
import * as querystring from 'querystring';
import { RouteComponentProps, withRouter } from 'react-router';
import { WeChatConfig } from '@store/models/wechat/types';
import { ApplicationState } from '@store';
import { bindActionCreators, Dispatch } from 'redux';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import { connect } from 'react-redux';

interface Personal {
	id: string,
	red_one: string,
	red_three: string,
	send_member_one: string,
	send_member_three: string
}

interface StringArr {
	label: string;
	value: string;
}
interface State {
	step: number;
	info: Personal;
}

interface StateProps {
	wechatConfig: WeChatConfig;
}

interface DispatchProps {
	loadWeChatRequest(): void;
}

type Props = RouteComponentProps & DispatchProps & StateProps;
class WriteInformation extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			step: 0,
			info: {
				id: "",
				red_one: "",
				red_three: "",
				send_member_one: '否',
				send_member_three: '否',
			}
		};
	}

	async componentDidMount() {
		this.updateDocumentTitle();
		const {
			loadWeChatRequest
		} = this.props;
		loadWeChatRequest();
		const param: any = querystring.parse(window.location.href.split('?')[1]);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			await getUserInfoByCode(param.code);
			window.location.replace(window.location.origin + window.location.pathname)
		}
		await getCurrentUserInfo();
	}

	async componentDidUpdate() {
		const { wechatConfig } = this.props;
		this.updateDocumentTitle();
		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res: any) => {
				console.log(res);
			});
		}
	}

	updateDocumentTitle() {
		document.title = '脱单实验室'
	}

	async updatePrice() {
		const { info } = this.state;
		try {
			let { id } = await customePrice({
				"red_one": {
					"price": info.red_one,
					"count": 1,
					"send_member": info.send_member_one == "是"
				},
				"red_three": {
					"price": info.red_three,
					"count": 3,
					"send_member": info.send_member_three == "是"
				}
			})
			if(!id) {
				Toast.info("请稍微重试",3);
			}
			if(id) {
				await this.setShare(id);
			}
		} catch(error:any) {
			console.log(error)
			if (error && error.msg) {
				Toast.info(error.msg,3);
			}
		}
	}

	async setShare(id:any): Promise<any> {
		return new Promise<void>((resolve,_)=> {
			window.wx.ready(() => {
				window.wx.updateAppMessageShareData({
					title: '高端1v1精准匹配，全新脱单模式闪亮登场',
					desc: '优质会员真实靠谱，资深红娘全程服务，精准高效速配脱单，数据分析深度认知，安全放心高度保密！',
					link: `http://mp.smcode.com.cn/custom/share/${id}`,
					imgUrl: 'https://static.hamu.site/blind-date/activity.png',
					success: function (res:any) {
						console.log(res);
						Toast.info("设置分享成功",3);
						resolve();
					}
				});
			});
		})
	}
	verifCoreData() {
		let { info : { red_one, red_three } } = this.state;
		if(!red_one) {
			Toast.info("请输入单次体验价",3);
			return false;
		}
		if(!red_three) {
			Toast.info("请输入三次体验价",3);
			return false;
		}
		return true;
	}

	async nextClick() {
        window.scrollTo(0, 0);
		const { step, info } = this.state;
		if(step == 0) {
			let flag = this.verifCoreData();
			if(!flag) {
				return;
			}
		}
		await this.updatePrice();
		return;
	}

	renderCoreData = () => {
		const {
			info,
			info: {
				red_one,red_three, send_member_one, send_member_three
			},
		} = this.state;
		return (
			<div className="custom_price_box">
				<List>
					<InputItem
						type="number"
						placeholder="请输入价格"
						value={red_one}
						onChange={(val) => {
							this.setState({
								info: { ...info, red_one: val }
							});
						}}
					>
						单次体验价格
					</InputItem>
					<List.Item
						extra={(
							<Switch
								checked={send_member_one == '是'}
								color="#EF5050"
								name={send_member_one}
								onChange={(checked) => {
									console.log(checked);
									this.setState({
										info: {
											...info,
											send_member_one: checked ? '是' : '否'
										}
									});
								}}
							/>
						)}
					>
						是否赠送会员
					</List.Item>
					<InputItem
						type="number"
						placeholder="请输入价格"
						value={red_three}
						onChange={(val) => {
							this.setState({
								info: { ...info, red_three: val }
							});
						}}
					>
						三次体验价格
					</InputItem>
					<List.Item
						extra={(
							<Switch
								checked={send_member_three == '是'}
								color="#EF5050"
								name={send_member_three}
								onChange={(checked) => {
									console.log(checked);
									this.setState({
										info: {
											...info,
											send_member_three: checked ? '是' : '否'
										}
									});
								}}
							/>
						)}
					>
						是否赠送会员
					</List.Item>
				</List>
			</div>
		);
	};

	onWrapTouchStart = (e: any) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		let pNode = null;
		const matchesSelector = e.target.matches || e.target.webkitMatchesSelector || e.target.mozMatchesSelector || e.target.msMatchesSelector;
		while (e.target) {
			if (matchesSelector.call(e.target, '.am-modal-content')) {
				pNode = e.target;
			}
			e.target = e.target.parentElement;
		}

		if (!pNode) {
			e.preventDefault();
		}
	}

	render() {
		const { step } = this.state;
		document.title = '脱单实验室'
		return (
			<div className="core_web_view" key={step}>
				<AppBar position="fixed">
					<Toolbar className="app__header">
						<Button
							onClick={() => this.nextClick()}
							className="next__btn"
						>
							保存
						</Button>
					</Toolbar>
				</AppBar>
				<div className="tips_core">
					自定义价格
				</div>
				{ this.renderCoreData() }
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions
}, dispatch);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WriteInformation));
// export default withRouter(WriteInformation);
