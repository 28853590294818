import React from "react";
import "./Recharge.scss";
import { Container, Box, Button } from "@material-ui/core";
import { ApplicationState } from "@store";
import { bindActionCreators, Dispatch } from "redux";
import * as MineActions from "@store/models/mine/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import * as querystring from "querystring";
import { WeChatUserInfo } from "@store/models/mine/types";
import { StorageCache } from "@libs/utils";
import { Skeleton } from "@material-ui/lab";
import {
	getCurrentUserInfo,
	getOrderStatusByTradeNo,
	getUserInfoByCode,
	getRechargeSetting,
	recharge,
	getWechatConfig,
} from "@service/api";
import _ from "lodash";
import { Toast } from "@components/FeedBack/ToastComponent/ToastComponent";

interface State {
	currentActive: number;
	payment: any;
	currentItem: any;
}
interface mineProps {
	code: string;
}
interface StateProps {
	wechatUserInfo: WeChatUserInfo;
}
interface DispatchProps {
	loadWeChatUserInfoRequest(p: string): void;
	loadCurrentUserInfoRequest(): void;
}
type Props = StateProps & DispatchProps & RouteComponentProps & mineProps;

class Recharge extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currentActive: 0,
			payment: {},
			currentItem: {},
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = "脱单币充值";
		await this.setWechatConfig();
		const { loadCurrentUserInfoRequest } = this.props;
		const param: any = querystring.parse(
			window.location.href.split("?")[1]
		);
		if (Object.prototype.hasOwnProperty.call(param, "code")) {
			await getUserInfoByCode(param.code);
			window.location.replace(window.location.pathname);
		} else {
			// loadCurrentUserInfoRequest();
		}
		this.getRecharge();
	}
	async getRecharge() {
		const payment = await getRechargeSetting();
		const mapList = _.sortBy(
			Object.values(_.get(payment, "setting") || {}),
			(o: any) => o.sort
		);
		const currentItem = mapList.length ? mapList[this.state.currentActive] : {};
		this.setState({
			payment,
			currentItem
		});
	}
	async componentDidUpdate() {
		document.title = "脱单币充值";
		await this.setWechatConfig();
	}

	async setWechatConfig() {
		const response = await getWechatConfig();
		if (response.appId) {
			window.wx.config(response);
			window.wx.error((res: any) => {
				console.log(res);
			});
			this.setShare();
		}
	}

	saveWeChatUserInfo(wechatUserInfo: WeChatUserInfo) {
		StorageCache.setItem("wxUserInfo", wechatUserInfo);
	}

	selectPrice = (item: any, index: number) => {
		this.setState({
			currentActive: index,
			currentItem: item,
		});
	};
	setShare(): void {
		window.wx.ready(() => {
			// 分享到朋友
			const url = window.location.origin + window.location.pathname;
			window.wx.updateAppMessageShareData({
				title: "脱单币充值",
				desc: "帮助广大程序员解决单身问题",
				link: url,
				imgUrl: "https://static.hamu.site/blind/logo.png",
				success: (res: any) => {},
				cancel: (err: any) => {},
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: "程序员脱单实验室-脱单币充值",
				link: url,
				imgUrl: "https://static.hamu.site/blind/logo.png",
				success: (res: any) => {},
				cancel: (err: any) => {},
			});
		});
	}
	payment = async () => {
		const { currentItem } = this.state;
		let payment: any = null;
		try {
			payment = await recharge(_.get(currentItem, "type"));
		} catch (error: any) {
			if (error && error.msg) {
				Toast.default({
					title: error.msg,
				});
			}
		}
		if (!payment) {
			return;
		}
		window.wx.ready(() => {
			window.wx.chooseWXPay(
				Object.assign(
					{},
					{
						timestamp: payment.timeStamp,
						nonceStr: payment.nonceStr,
						package: payment.package,
						signType: payment.signType,
						paySign: payment.paySign,
					},
					{
						success: () => {
							Toast.show({
								title: "支付中，请稍等……",
							});
							let start = Date.now();
							let timer = setInterval(async () => {
								const result = await getOrderStatusByTradeNo(
									payment.out_trade_no
								);
								if (result.status === "支付成功") {
									clearInterval(timer);
									await getCurrentUserInfo();
									Toast.hide();
									this.getRecharge();
									Toast.default({ title: "充值成功" });
									return;
								}
								let expire = Date.now() - start;
								if (expire > 30000) {
									clearInterval(timer);
									await getCurrentUserInfo();
									Toast.hide();
									this.getRecharge();
								}
							}, 1000);
						},
						fail: () => {
							Toast.hide({
								callback: () => {
									Toast.default({
										title: "微信支付失败",
									});
								},
							});
						},
					}
				)
			);
		});
	};

	render() {
		const { wechatUserInfo } = this.props;
		const { currentActive, payment } = this.state;

		if (wechatUserInfo.openid) {
			this.saveWeChatUserInfo(wechatUserInfo);
		}
		
		const mapList = _.sortBy(
			Object.values(_.get(payment, "setting") || {}),
			(o: any) => o.sort
		);
		console.log(mapList, "mapList");

		return (
			<Container className="recharge">
				<Box className="recharge-header">
					{_.get(payment, "user.headimgurl") ? (
						<img src={_.get(payment, "user.headimgurl")} alt="" />
					) : (
						<Skeleton
							animation="wave"
							variant="circle"
							width={69}
							height={69}
						/>
					)}
					{_.get(payment, "user.nickname") ? (
						<div>{_.get(payment, "user.nickname")}</div>
					) : (
						<Skeleton
							animation="wave"
							variant="text"
							width={105}
							height={24}
						/>
					)}
					<div className="balance">
						<span>脱单币余额</span>
						<span className="balance_coin">
							{_.get(payment, "user.coin")}
						</span>
					</div>
				</Box>
				<Box className="selected">
					{_.map(mapList, (item: any, index: number) => (
						<div
							key={index}
							className={`s-box ${
								index === currentActive ? "active " : ""
							}`}
							onClick={() => this.selectPrice(item, index)}
						>
							{_.get(item, "reduce") ? (
								<div
									className={
										index === currentActive
											? "reduceActive"
											: "reduce"
									}
								>
									立减{_.get(item, "reduce")}元
								</div>
							) : null}
							<div
								className={
									_.get(item, "reduce")
										? "numberReduce"
										: "number"
								}
							>
								<span>{item.coin}</span>
								<div className="icon"></div>
							</div>
							<div className="money">{item.price}元</div>
						</div>
					))}
				</Box>
				<div className="payment">
					<Button
						className="rechargeBtn"
						variant="contained"
						onClick={this.payment}
					>
						立即充值
					</Button>
				</div>
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatUserInfo: state.wechatUserInfo.data.openid
		? state.wechatUserInfo.data
		: state.currentUserInfo.data,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			...MineActions,
		},
		dispatch
	);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Recharge)
);
