import React, { Component } from 'react';
import {
	AppBar, Toolbar, Typography, Button
} from '@material-ui/core';
import {
	getCustomerInfo, getQuestions, getTokenToUpload, updateCustomerInfo, getMiniWechatConfig
} from '@service/api';
import * as qiniu from 'qiniu-js';
import {
	List,
	InputItem,
	Switch,
	DatePicker,
	Picker,
	TextareaItem,
	WingBlank,
	ImagePicker,
	WhiteSpace,
	Modal,
	Radio,
	Toast
} from 'antd-mobile';
import './_index.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import cityData from '@libs/cityFormat';
import headimg from '@images/headimg.png';
import progressBar from '@images/progress_bar.png';

interface PrivacyInformation {
	微信号: string;
	手机号: string;
	QQ号: number | string,
	年收入: number | string,
	是否购房: string,
	其他经济条件: string,
	恋爱经历: string,
	家庭背景: string
}

interface IdealObject {
	年龄范围: any[];
	坐标: string;
	外貌: string;
	学历: string;
	性格: string;
	是否有房: string;
	年收入: string;
	补充: string;
}

interface Personal {
	name: string;
	gender: string;
	height: number | string;
	birthday: any;
	education: string;
	job: string;
	living_place: string[];
	hometown: string[];
	is_only: string[];
	only_content: string;
	cover_img: string;
	img_list: string[];
}

interface StringArr {
	label: string;
	value: string;
}
interface State {
	temp: any;
	step: number;
	checked: boolean;
	info: Personal;
	educationList: StringArr[];
	privacy_information: PrivacyInformation;
	introduce: string[];
	ideal_object: IdealObject;
	ageList: any[];
	titleList: string[];
	cityData: any[];
	questionModal: boolean;
	questions_answers: any[];
	qaIndex: number;
	questionChecked: number;
	questionList: any[];
	addr: (string | number)[] | undefined;
}

type Props = RouteComponentProps;
class WriteInformation extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			temp: {},
			step: 0,
			checked: true,
			questionChecked: -1,
			questionModal: false,
			educationList: [
				{
					label: '专科',
					value: '专科'
				},
				{
					label: '本科',
					value: '本科'
				},
				{
					label: '硕士',
					value: '硕士'
				},
				{
					label: '博士',
					value: '博士'
				}
			],
			info: {
				name: '',
				gender: '男',
				height: '',
				birthday: new Date(1990, 0, 1, 0, 0, 0),
				education: '',
				job: '',
				living_place: [],
				hometown: [],
				is_only: ['是'],
				only_content: '',
				cover_img: '',
				img_list: []
			},
			privacy_information: {
				微信号: '',
				手机号: '',
				QQ号: '',
				年收入: '',
				家庭背景: '',
				是否购房: '',
				其他经济条件: '',
				恋爱经历: ''
			},
			qaIndex: 0,
			introduce: ['', '', ''],
			ideal_object: {
				坐标: '',
				外貌: '',
				学历: '',
				年龄范围: [0, 99],
				性格: '',
				是否有房: '',
				年收入: '',
				补充: ''
			},
			ageList: [],
			titleList: ['核心资料', '上传头像', '自我介绍', '理想对象'],
			cityData,
			questionList: [],
			questions_answers: [{ A: '', Q: '' }, { A: '', Q: '' }, { A: '', Q: '' }],
			addr: []
		};
	}

	async componentDidMount() {
		await this.setWechatConfig();
		const { location: { search } } = this.props;
		const { match: { params } } :any = this.props;
		if (params && params.step) {
			this.setState({
				step: parseInt(params.step)
			});
		}
		document.title = '脱单实验室';
		let ageList: any[] = [];

		ageList = this.loopAge(ageList);

		ageList = ageList.map((age:any) => {
			age.children = [];
			age.chilren = this.loopAge(age.children, age.value + 1, 46);
			return age;
		});

		this.setState({
			ageList
		});
		getCustomerInfo().then((res:any) => {
			this.updateRes(res);
		});

		getQuestions().then((res) => {
			const questionList: { content: any; }[] = [];
			Object.values(res).forEach((item:any) => {
				questionList.push({
					content: item
				});
			});
			this.setState({
				questionList
			});
		});
	}

	async setWechatConfig() {
		const response = await getMiniWechatConfig();
		if (response.appId) {
			window.wx.config(response);
			window.wx.error((res:any) => {
				console.log(res);
			});
		}
	}

	loopAge =(agetList: any[], ageDefault = 18, max = 45) => {
		for (let age = ageDefault; age < max; age++) {
			agetList.push({
				label: `${age}`,
				value: age
			});
		}
		return agetList;
	}

	async preClick() {
		window.scrollTo(0, 0);
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
		this.updateCustomer();
	}

	updateRes(res:any) {
		res.birthday = new Date(res.birthday);
		this.setState({
			temp: res,
			info: Object.assign(this.state.info, res),
			introduce: Object.assign(this.state.introduce, res.introduce),
			questions_answers: Object.assign(this.state.questions_answers, res.questions_answers),
			ideal_object: Object.assign(this.state.ideal_object, res.ideal_object),
			privacy_information: Object.assign(this.state.privacy_information, res.privacy_information)
		});
	}

	async updateCustomer() {
		const {
			introduce, questions_answers, ideal_object, privacy_information, info
		} = this.state;
		const res = await updateCustomerInfo({
			...this.state.temp,
			...info,
			introduce,
			questions_answers,
			ideal_object,
			privacy_information,
			birthday: new Date(info.birthday).getTime(),
			operate_type: '微信小程序审核',
			"status": this.state.step === 3 ? "提交审核" : (this.state.temp.status || "未提交审核")
		});
		this.updateRes(res);
	}

	verifCoreData() {
		let {
			info: {
				name, height, birthday, living_place, hometown, education, job, is_only, only_content
			}, privacy_information: { 微信号, 手机号, 年收入 }, introduce, step, info: { cover_img },
			ideal_object, ideal_object: { 年龄范围, 学历, 坐标 }
		} = this.state;
		if (step === 0) {
			if (!name) {
				Toast.info('请输入姓名', 3);
				return false;
			}
			if (!height) {
				Toast.info('请输入身高', 3);
				return false;
			}
			if (new Date(birthday).getTime() == 0) {
				Toast.info('请输入出生年月', 3);
				return false;
			}
			living_place = living_place.filter((item) => item);
			hometown = hometown.filter((item) => item);
			if (living_place.length == 0) {
				Toast.info('请输入现居地', 3);
				return false;
			}
			if (hometown.length == 0) {
				Toast.info('请输入家乡', 3);
				return false;
			}
			if (!education) {
				Toast.info('请填写学历', 3);
				return false;
			}
			if (!job) {
				Toast.info('请填写职业', 3);
				return false;
			}
			if (is_only.includes('否') && !only_content) {
				Toast.info('请填写您的兄弟姐妹情况', 3);
				return false;
			}
			if (!微信号) {
				Toast.info('请填写您的微信号', 3);
				return false;
			}

			if (!手机号 || 手机号.length < 11) {
				Toast.info(`请${!手机号 ? '' : '正确'}填写您的手机号`, 3);
				return false;
			}
			if (!年收入) {
				Toast.info('请填写您的年收入', 3);
				return false;
			}
		}

		if (step === 1) {
			// eslint-disable-next-line camelcase
			if (!cover_img) {
				Toast.info('请上传头像', 3);
				return false;
			}
		}

		if (step === 2) {
			if (!introduce[0]) {
				Toast.info('请填写您的性格特征', 3);
				return false;
			}

			if (!introduce[1]) {
				Toast.info('请填写您的兴趣爱好', 3);
				return false;
			}
		}

		if (step === 3) {
			if (年龄范围[0] === 0 && 年龄范围[1] === 99) {
				Toast.info('请选择理想对象的年龄范围', 3);
				return false;
			}

			if (!学历) {
				Toast.info('请填写理想对象的学历要求', 3);
				return false;
			}

			if (!坐标) {
				Toast.info('请填写理想对象的坐标要求', 3);
				return false;
			}

			if (!ideal_object.年收入) {
				Toast.info('请填写理想对象的年收入最低要求', 3);
				return false;
			}
		}
		return true;
	}

	async nextClick() {
		window.scrollTo(0, 0);
		const { step } = this.state;
		const flag = this.verifCoreData();
		if (!flag) {
			return;
		}
		await this.updateCustomer();

		if (step === 3) {
			window.location.href = `/mini/question/end`;
			return;
		}
		this.setState({
			step: step + 1
		});
	}

	onChange = async (files: any, operationType: string, index?: number) => {
		const { info, info: { img_list } } = this.state;
		console.log(files);
		if (operationType === 'remove' && index !== undefined) {
			const imgList = img_list;
			imgList.splice(index, 1);
			this.setState({
				info: { ...info, img_list: imgList }
			});
			return;
		}
		Toast.loading('上传中...', 120);
		const cover_img: any = await this.uploadImg(files[files.length - 1].file);
		this.setState({
			info: { ...this.state.info, img_list: img_list.concat(cover_img) }
		});
		Toast.hide();
	};

	onCoverChange = async (files: any, operationType: string, index?: number) => {
		if (operationType === 'remove') {
			this.setState({
				info: { ...this.state.info, cover_img: '' }
			});
			return;
		}
		Toast.loading('上传中...', 120);
		const cover_img: any = await this.uploadImg(files[0].file);
		this.setState({
			info: { ...this.state.info, cover_img }
		});
		Toast.hide();
	};

	base64toFile(dataurl:string, filename = 'file') {
		dataurl = dataurl.startsWith('data:image') ? dataurl : 'data:image/png;base64,' + dataurl;
		const arr:any = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const suffix = mime.split('/')[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
		  u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], `${filename}.${suffix}`, {
		  type: mime
		});
	}

	uploadImg = (file: any): Promise<string> => new Promise((resolve, reject) => getTokenToUpload().then((res: any) => {
		file = this.base64toFile(file);
		const observable = qiniu.upload(file, res.key, res.token, {
			fname: res.key
		}, {
			useCdnDomain: true,
			region: qiniu.region.z0,
			checkByMD5: true
		});
		observable.subscribe({
			next: () => {},
			error: (err) => {
				reject(err);
			},
			complete: (result) => {
				resolve(`${res.domain}/${result.key}`);
			}
		});
	}))

	wxReady() {
		return new Promise<void>((resolve, reject) => {
			window.wx.ready(() => {
				resolve();
			});
			window.wx.error((error:any) => {
				reject(error);
			});
		});
	}

	chooseImage() {
		return new Promise((resolve) => {
			window.wx.chooseImage({
				count: 1, // 默认9
				sizeType: ['original', 'compressed'],
				sourceType: ['album', 'camera'],
				success: (res: any) => {
					resolve(res);
				}
			});
		});
	}

	uploadImage = async () => {
		await this.wxReady();
		const res:any = await this.chooseImage();
		const localId = res.localIds[0];
		window.wx.getLocalImgData({
			localId,
			success: ({ localData }:any) => {
				this.uploadImg(localData).then((res:string) => {
					this.setState({
						info: { ...this.state.info, cover_img: res }
					});
				});
			}
		});
	}

	renderCoreData = () => {
		const {
			info,
			info: {
				name, gender, birthday, education, job, height, living_place, hometown, is_only, only_content
			},
			educationList,
			cityData,
			privacy_information,
			privacy_information: {
				微信号, 手机号, 年收入, 是否购房
			}
		} = this.state;
		return (
			<div className="core__data">
				<List>
					<InputItem
						placeholder="请填写您的姓名"
						value={name}
						onChange={(val) => {
							this.setState({
								info: { ...info, name: val }
							});
						}}
					>
						真实姓名
					</InputItem>
					<List.Item
						extra={(
							<Switch
								checked={gender == '男'}
								color="#EF5050"
								name={gender}
								onChange={(checked) => {
									console.log(checked);
									this.setState({
										checked,
										info: {
											...info,
											gender: checked ? '男' : '女'
										}
									});
								}}
							/>
						)}
					>
						性别
					</List.Item>
					<InputItem
						placeholder=""
						extra="cm"
						type="number"
						value={`${height == 0 ? '' : height}`}
						onChange={(val) => {
							this.setState({
								info: { ...info, height: Number(val) }
							});
						}}
					>
						身高
					</InputItem>
					<DatePicker
						mode="date"
						title="出生年月"
						extra="请选择出生年月"
						minDate={new Date(1970, 0, 1, 0, 0, 0)}
						value={birthday}
						onChange={(birthday: Date) => {
							this.setState({
								info: { ...info, birthday }
							});
						}}
					>
						<List.Item>出生年月</List.Item>
					</DatePicker>
					<Picker
						extra="请选择现居地"
						data={cityData}
						title="区域"
						value={living_place}
						onOk={(living_place: string[]) => {
							this.setState({
								info: { ...info, living_place }
							});
						}}
					>
						<List.Item arrow="down">现居地</List.Item>
					</Picker>
					<Picker
						extra="请选择家乡"
						data={cityData}
						title="区域"
						value={hometown}
						onOk={(hometown: string[]) => {
							this.setState({
								info: { ...info, hometown }
							});
						}}
					>
						<List.Item arrow="down">家乡</List.Item>
					</Picker>
					<Picker
						data={educationList}
						cols={1}
						extra="请选择学历"
						value={[education]}
						onOk={(val: any) => {
							this.setState({
								info: { ...info, education: val[0] }
							});
						}}
					>
						<List.Item arrow="down">学历</List.Item>
					</Picker>
					<InputItem
						placeholder="请填写您的职业"
						value={job}
						onChange={(val: any) => {
							this.setState({
								info: { ...info, job: val }
							});
						}}
					>
						职业
					</InputItem>
					<InputItem
						placeholder="请填写您的微信号"
						value={微信号}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 微信号: val }
							});
						}}
					>
						微信号
					</InputItem>
					<InputItem
						placeholder="请填写您的手机号"
						type="number"
						value={`${手机号}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 手机号: val }
							});
						}}
					>
						手机号
					</InputItem>
					<InputItem
						placeholder=""
						extra="万"
						type="number"
						value={`${年收入 == 0 ? '' : 年收入}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 年收入: Number(val) }
							});
						}}
					>
						年收入
					</InputItem>
					<div className="private_box_house">
						<Picker
							extra="请选择"
							data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
							title=""
							cols={1}
							value={[是否购房]}
							onOk={(value: string[]) => {
								this.setState({
									privacy_information: { ...privacy_information, 是否购房: value[0] }
								});
							}}
						>
							<List.Item arrow="down">是否购房</List.Item>
						</Picker>
					</div>
					<div className="only_box">
						<Picker
							extra="请选择"
							data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
							title=""
							cols={1}
							value={is_only}
							onOk={(only: string[]) => {
								this.setState({
									info: { ...info, is_only: only }
								});
							}}
						>
							<List.Item arrow="down">是否独生子女</List.Item>
						</Picker>
						{is_only.includes('否') ? (
							<div className="only_box_item">
								<InputItem
									placeholder="请填写您的兄弟姐妹情况"
									value={only_content}
									onChange={(val: any) => {
										this.setState({
											info: { ...info, only_content: val }
										});
									}}
								/>
							</div>
						) : ''}
					</div>
					{/* <InputItem
						placeholder=""
						value={家庭背景}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 家庭背景: val === '是' ? '是独生子女' : val }
							});
						}}
					>
						是否独生子女
					</InputItem> */}
				</List>
			</div>
		);
	};

	renderSelfIntroduction = () => {
		const {
			introduce, info: { cover_img, img_list }, privacy_information, privacy_information: { 家庭背景, 恋爱经历 }
		} = this.state;
		return (
			<div className="self__introduction">
				<List renderHeader={() => (
					<div>
						<span className="red">*</span>
						性格特征
					</div>
				)}
				>
					<TextareaItem
						placeholder="性格开朗"
						autoHeight
						value={introduce[0]}
						onChange={(val: string | any) => {
							const intro = JSON.parse(JSON.stringify(introduce));
							intro[0] = val || '';
							this.setState({
								introduce: intro
							});
						}}
					/>
				</List>
				<List renderHeader={() => (
					<div>
						<span className="red">*</span>
						兴趣爱好
					</div>
				)}
				>
					<TextareaItem
						autoHeight
						placeholder="有什么兴趣爱好？坚持了多久了？有获得什么荣誉或成就吗？"
						value={introduce[1]}
						onChange={(val: string | any) => {
							const intro = introduce;
							intro[1] = val;
							this.setState({
								introduce: [...intro]
							});
						}}
					/>
				</List>
				<List renderHeader={() => '家庭情况'}>
					<TextareaItem
						autoHeight
						placeholder="家里几个人，父母工作是什么？详细介绍介绍"
						value={家庭背景}
						onChange={(val: string | any) => {
							this.setState({
								privacy_information: { ...privacy_information, 家庭背景: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '恋爱经历'}>
					<TextareaItem
						autoHeight
						placeholder="有过几段恋爱？为什么分手了？"
						value={恋爱经历}
						onChange={(val: string | any) => {
							this.setState({
								privacy_information: { ...privacy_information, 恋爱经历: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '其他补充'}>
					<TextareaItem
						autoHeight
						value={introduce[2]}
						onChange={(val: string | any) => {
							const intro = introduce;
							intro[2] = val;
							this.setState({
								introduce: [...intro]
							});
						}}
					/>
				</List>
			</div>
		);
	};

	renderIdealObject = () => {
		const {
			ideal_object, info, ideal_object: {
				年龄范围, 是否有房, 外貌, 性格, 学历, 坐标, 年收入, 补充
			}, ageList, introduce, addr
		} = this.state;

		return (
			<div className="ideal__object">
				<List>
					<Picker
						data={ageList}
						extra="请选择年龄范围"
						format={(labels) => `${labels.join('-')}岁`}
						cols={2}
						value={年龄范围}
						onOk={(val: any) => {
							this.setState({
								ideal_object: { ...ideal_object, 年龄范围: val }
							});
						}}
					>
						<List.Item>
							<span className="red">*</span>
							年龄范围
						</List.Item>
					</Picker>
				</List>
				<Picker
					extra="请选择"
					data={[{ value: '无所谓', label: '无所谓' }, { value: '本科以及以上', label: '本科以及以上' }, { value: '硕士以及以上', label: '硕士以及以上' }]}
					title=""
					cols={1}
					value={[学历]}
					onOk={(value: string[]) => {
						this.setState({
							ideal_object: { ...ideal_object, 学历: value[0] }
						});
					}}
				>
						<List.Item arrow="down">
							<span className="red">*</span>学历要求
						</List.Item>
				</Picker>
				{/* <List renderHeader={() => (
					<div>
						<span className="red">*</span>
						学历要求
					</div>
				)}
				>
					<TextareaItem
						autoHeight
						value={学历}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 学历: val }
							});
						}}
					/>
				</List> */}
				<List>
					<Picker
						data={cityData[0].children}
						title="坐标"
						extra="请选择坐标"
						cols={2}
						format={() => 坐标}
						value={addr}
						onOk={(val: any) => {
							this.setState({
								ideal_object: { ...ideal_object, 坐标: val.join(',') },
								addr: val
							});
						}}
					>
						<List.Item>
							<span className="red">*</span>
							坐标要求
						</List.Item>
					</Picker>
				</List>
				{/* <List renderHeader={() => ''}>
					<TextareaItem
						autoHeight
						value={坐标}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 坐标: val }
							});
						}}
					/>
				</List> */}
				{/* <List renderHeader={() => (
					<div>
						<span className="red">*</span>
						年收入最低要求
					</div>
				)}
				>
					<InputItem
						className="ideal_object_box"
						placeholder=""
						type="number"
						value={`${年收入}`}
						onChange={(val) => {
							this.setState({
								ideal_object: { ...ideal_object, 年收入: val }
							});
						}}
					/>
				</List> */}
				<InputItem
						placeholder=""
						extra="万"
						type="number"
						value={`${年收入}`}
						className="long__title"
						onChange={(val) => {
							this.setState({
								ideal_object: { ...ideal_object, 年收入: val }
							});
						}}
					>
						<span className="red">*</span>年收入最低要求
				</InputItem>
				<List renderHeader={() => '外貌要求'}>
					<TextareaItem
						autoHeight
						value={外貌}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 外貌: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '性格要求'}>
					<TextareaItem
						autoHeight
						value={性格}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 性格: val }
							});
						}}
					/>
				</List>
				<div className="private_box_house">
					<Picker
						extra="请选择"
						data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
						title=""
						cols={1}
						value={[是否有房]}
						onOk={(value: string[]) => {
							this.setState({
								ideal_object: { ...ideal_object, 是否有房: value[0] }
							});
						}}
					>
						<List.Item arrow="down">是否有房</List.Item>
					</Picker>
				</div>
				<List renderHeader={() => '补充信息'}>
					<TextareaItem
						autoHeight
						value={补充}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 补充: val }
							});
						}}
					/>
				</List>
			</div>
		);
	};

	renderPrivacyInfo = () => {
		const {
			info, info: { cover_img }
		} = this.state;
		return (
			<div className="upload__img">
				<p className="head__img">请上传真实头像</p>
				<p className="head__img--tips">上传真实头像，好友通过率上升100%</p>
				<img src={cover_img || headimg} className={`head__img-upload ${cover_img ? 'br' : ''}`} onClick={() => this.uploadImage()} />
				<img src={progressBar} className="progress__bar" />
				<p className="progress__text">资料填写您已完成50%，请继续坚持</p>
			</div>
		);
	}

	onWrapTouchStart = (e: any) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		let pNode = null;
		const matchesSelector = e.target.matches || e.target.webkitMatchesSelector || e.target.mozMatchesSelector || e.target.msMatchesSelector;
		while (e.target) {
			if (matchesSelector.call(e.target, '.am-modal-content')) {
				pNode = e.target;
			}
			e.target = e.target.parentElement;
		}

		if (!pNode) {
			e.preventDefault();
		}
	}

	// renderQuestion = () => {
	// 	const {
	// 		questions_answers, questionModal, qaIndex, questionList, questionChecked
	// 	} = this.state;
	// 	return (
	// 		<div className="question">
	// 			{
	// 				questions_answers.map((qa, index) => (
	// 					<List key={`${index}qa`}>
	// 						<WhiteSpace size="lg" />
	// 						<List.Item
	// 							arrow="down"
	// 							onClick={() => {
	// 								this.setState({
	// 									questionModal: true,
	// 									qaIndex: index,
	// 									questionChecked: -1
	// 								});
	// 							}}
	// 						>
	// 							{ qa.Q ? qa.Q : `请选择问题${index === 0 ? '一' : (index === 1 ? '二' : '三')}` }
	// 						</List.Item>
	// 						<TextareaItem
	// 							autoHeight
	// 							value={qa.A}
	// 							onChange={(val: string | any) => {
	// 								const QA = questions_answers;
	// 								QA[index].A = val;
	// 								this.setState({
	// 									questions_answers: QA
	// 								});
	// 							}}
	// 						/>
	// 					</List>
	// 				))
	// 			}
	// 			<Modal
	// 				visible={questionModal}
	// 				transparent
	// 				maskClosable
	// 				onClose={() => {
	// 					this.setState({
	// 						questionModal: false
	// 					});
	// 				}}
	// 				title="请选择一个问题进行回答"
	// 				wrapProps={{ onTouchStart: this.onWrapTouchStart }}
	// 			>
	// 				<div className="modal__question">
	// 					<List>
	// 						{
	// 							questionList.map((question, index) => (
	// 								<div key={question.content} className="question__checked">
	// 									<Radio
	// 										className="my-radio"
	// 										checked={questionChecked === index}
	// 										onChange={() => {
	// 											const QA = questions_answers;
	// 											QA[qaIndex].Q = question.content;
	// 											this.setState({
	// 												questionModal: false,
	// 												questions_answers: QA,
	// 												questionChecked: index
	// 											});
	// 										}}
	// 									/>
	// 									<List.Item wrap>
	// 										{ question.content }
	// 									</List.Item>
	// 								</div>
	// 							))
	// 						}
	// 					</List>
	// 				</div>
	// 			</Modal>
	// 		</div>
	// 	);
	// }

	render() {
		const { step, titleList } = this.state;
		document.title = titleList[step];
		return (
			<div className="write_web_view" key={step}>
				<div className="webview_tips">
					填写资料后，我们的运营同学将与你对接，审核通过后可获得曝光
				</div>
				{ step === 0 ? this.renderCoreData() : null}
				{ step === 1 ? this.renderPrivacyInfo() : null }
				{ step === 2 ? this.renderSelfIntroduction() : null}
				{ step === 3 ? this.renderIdealObject() : null }
				<AppBar position="fixed">
					<Toolbar className="app__header">
						{step === 0 ? (
							<div />
						) : (
							<Button
								onClick={() => this.preClick()}
								className="next__btn"
							>
								上一步
							</Button>
						)}
						<Button
							onClick={() => this.nextClick()}
							className={step === 0 ? "next__btn first" : "next__btn"}
						>
							{ step === 3 ? '提交审核' : '下一步' }
						</Button>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

export default withRouter(WriteInformation);
