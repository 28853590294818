import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./RechargeProtocol.scss";

interface State {
	key: any;
}
interface mineProps {}
interface DispatchProps {}
type Props = DispatchProps & RouteComponentProps & mineProps;

class RechargeProtocol extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			key: "",
		};
	}

	componentDidMount() {
		this.updateDocumentTitle();
		this.setState({
			key: Date.now(),
		});
	}

	componentDidUpdate() {
		this.updateDocumentTitle();
	}

	updateDocumentTitle() {
		document.title = "在线充值服务协议";
	}

	render() {
		return (
			<div className="RechargeProtocol">
				<p className="RechargeParagraph">
					脱单实验室（以下简称“本平台”）按照《在线充值服务协议》（以下简称“本协议”）的规定提供基于互联网的充值服务，为获得充值服务，服务使用人(以下称“用户”或“您”)同意本协议的全部条款并按照页面上的提示完成全部的充值程序。
				</p>
				<p className="RechargeParagraph">
					用户在使用本平台提供的各项服务之前，应仔细阅读本服务协议，
					<strong>特别是免除或者限制责任的条款。</strong>
					<u>如用户不同意本协议及/或对其的修改，</u>
					<strong>
						<u>请停止使用本平台提供的充值服务，</u>
						用户在进行充值程序过程中使用充值服务即表示用户完全接受本协议项下的全部条款。
					</strong>
				</p>
				<p className="RechargeParagraph">
					<strong>
						<u>
							特别提示：本平台坚决反对未成年人在未经监护人同意的情况下使用充值服务，未成年人请委托监护人操作或在监护人明示同意下操作，否则请勿使用本充值服务。
						</u>
					</strong>
				</p>
				<div className="RechargeTitle">1.服务说明</div>
				<p className="RechargeParagraph">
					本协议中的服务包括脱单实验室VIP会员、脱单币等服务。每一项服务分别计费并分别享受权利，您可以根据您的需要选择特定的付费服务。
				</p>
				<p className="RechargeParagraph">
					脱单实验室的服务中，VIP会员以1个月、3个月或12个月为单位购买。1个月按31天计算，3个月93天，十二个月372天。使用不足1天的，按使用了1天计算。
				</p>
				<div className="RechargeTitle">1.1 关于VIP会员特权</div>
				<p className="RechargeParagraph">
					脱单实验室VIP会员用户除了可以使用普通注册用户拥有的功能外，还将享有多种专享的增值服务，详细说明如下：
				</p>
				<p className="RechargeParagraph">
					1.1.1 查看所有嘉宾：会员可查看全站所有嘉宾，普通用户仅可查看21位，此VIP只提供查看功能，申请微信需另付费。
				</p>
				<p className="RechargeParagraph">
					1.1.2 查看喜欢我的人：会员可解锁所有查看喜欢我的人。会员可跳过申请，直接免费查看所有喜欢我的人的微信。
				</p>
				<p className="RechargeParagraph">
					1.1.3 高级筛选功能：会员可根据收入学历购房等条件筛选嘉宾。
				</p>
				<div className="RechargeTitle">1.2 关于脱单币</div>
				<p className="RechargeParagraph">
					脱单币是脱单实验室平台为用户提供的用于脱单实验室相关消费的虚拟货币，也是脱单实验室充值平台的中间货币，脱单币不能兑换成人民币。
				</p>
				<div className="RechargeTitle">1.3 服务内容</div>
				<div className="RechargeParagraph">
					用户使用相关平台进行充值，可以购买VIP会员特权或为脱单实验室平台账号充值脱单币。
				</div>
				<div className="RechargeTitle">1.4 操作方法</div>
				<p className="RechargeParagraph">
					1.4.1 用户可以在此充值系统上自由选择具体的充值方式，并按相应页面提示的程序完成充值。
				</p>
				<p className="RechargeParagraph">
					1.4.2 包月类线上服务包月类型包括一个月、一个季度(3个月)和一年(12个月)三类。基于不同的手机操作系统，价格可能因系统平台方原因有所差别，具体的每项增值服务费用标准以购买界面的实际标价为准，上述费用包含所有税费。
				</p>
				<div className="RechargeTitle">1.5 充值成功后 </div>
				<p className="RechargeParagraph">
					1.5.1 充值成功后，充值所增加的账号内脱单币可由用户自由使用，但脱单实验室平台不会提供任何退还或逆向兑换服务。
				</p>
				<div className="RechargeTitle">2.服务使用规则（重要条款） </div>
				<div className="RechargeTitle">2.1 充值真实准确性 </div>
				<p className="RechargeParagraph">
					2.1.1 您可以在充值服务界面上自由选择具体的充值方式，并按页面提示的程序完成充值。
					<strong>
						<u>
							请您在充值前，务必仔细核账户ID、支付金额等具体信息。
						</u>
					</strong>
				</p>
				<p className="RechargeParagraph">
					2.1.2 您在使用具体充值方式进行充值后，应保留充值订单号以作为今后发生问题核对依据凭证
					<strong>
						（用户就充值服务投诉，但未有有效凭证佐证的，本平台将不会作出补偿或赔偿）。
					</strong>
					若因为用户自身操作不当或不按照充值流程操作等因素造成选错充错金额、发生充值错误、充错账号等情形而损害自身权益的，
					<strong>
						<u>本平台将不会作补偿或赔偿。</u>
					</strong>
				</p>
				<div className="RechargeTitle">2.2 充值合法正当性 </div>
				<p className="RechargeParagraph">
					2.2.1 用户必须遵循本协议规则及国家法律法规，严格按照国家有关监管部门规定使用本平台提供的充值服务，如用户在充值时使用第三方支付机构提供的服务的，还应当遵守与该第三方的各项协议及其服务规则。
				</p>
				<p className="RechargeParagraph">
					2.2.2 <strong>您应保证并承诺，<u>在本平台充值使用的资金来源为您的合法财产，充值行为不存在任何违法犯罪目的。</u>如您充值所用资金被国家司法机关、执法机关认定为非法并导致相关司法机关、执法机关对本平台银行账户采取冻结、划扣在内的强制措施；造成的一切损失由您负责向本平台赔偿，包括但不限于本平台因主张权利而支出的公证费、诉讼费、保全费、律师费以及被强制划扣的全部金额。</strong>
				</p>
				<p className="RechargeParagraph">
					2.2.3 请您知悉，若您以非法的方式，或使用非本平台所指定的充值方式进行充值，本平台不保证该充值顺利或者正确完成。<strong>因此造成用户权益受损的，本平台不会作补偿或赔偿，同时本平台保留随时冻结、注销该用户账号以及追究平台遭受损失的权利。</strong>
				</p>
				<p className="RechargeParagraph">
					2.2.4 用户不得通过本平台充值进行任何违法犯罪活动（如洗钱、套现等），不得利用充值漏洞谋利，否则本平台有权终止服务，追缴非法获利，情节严重的，依法移交司法机关。
				</p>
				<div className="RechargeTitle">2.3 充值安全规范 </div>
				<p className="RechargeParagraph">
					2.3.1 用户请知悉，<strong>在使用充值服务过程中您应妥善保管自身个人信息，</strong>包括但不限于银行账号、密码、验证码等。
				</p>
				<p className="RechargeParagraph">
					2.3.2 本平台禁止用户盗用、冒用他人平台账号进行充值或使用服务功能，如经发现，本平台将暂停或终止该账户部分或全部功能服务，且不退还或支付任何款项。
				</p>
				<p className="RechargeParagraph">
					2.3.3 <strong>为保证用户充值资金安全，在使用充值服务过程中，您应按照页面提示进行相关验证操作；</strong>
				</p>
				<div className="RechargeTitle">3.协议变更及不可抗力 </div>
				<p className="RechargeParagraph">
					3.1 您理解并认可，本平台可根据国家法律法规变化或本平台服务变化的需要更新或修改本协议，并通过合理的方式向您送达修改通知，包括但不限于系统通知、弹出消息推送等，如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用本平台服务；
					<strong>如您在变更事项生效后仍继续使用本平台服务，则视为您同意已生效的变更事项。</strong>
				</p>
				<p className="RechargeParagraph">
					3.2 <strong>由不可抗力及不可预见的情势导致的各种情况和纠纷，本平台将尽可能提前通知您，您理解并同意平台对此无需承担责任，</strong>不可抗力和不可预见情势包括但不限于：黑客攻击、电信部门技术调整导致重大影响、政府管制导致的暂时关闭、病毒侵袭。
				</p>
				<div className="RechargeTitle">4.法律适用、管辖及其他 </div>
				<p className="RechargeParagraph">
					4.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律法规。若用户与本平台因本协议发生争议的，我们将与您协商解决。协商不成时，双方同意将争议提交<strong><u>本平台备案主体所在地人民法院</u></strong>提起诉讼。
				</p>
				<p className="RechargeParagraph">
					4.2 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
				</p>
				<p className="RechargeParagraph">
					4.3 <strong><u>脱单实验室平台关于本在线充值协议的相关公告、规则及后续更新文件等为本协议重要组成部分，对双方均有法律约束力。</u></strong>
				</p>
				<p><u>在此，您再次保证已经完全阅读并充分理解了本服务条款，自觉遵守中华人民共和国的法律，接受上述所有条款的约束。</u></p>
			</div>
		);
	}
}

export default withRouter(RechargeProtocol);
