import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./UserAgreement.scss";

interface State {
	key: any;
}
interface mineProps {}
interface DispatchProps {}
type Props = DispatchProps & RouteComponentProps & mineProps;

class UserAgreement extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			key: "",
		};
	}

	componentDidMount() {
		this.updateDocumentTitle();
		this.setState({
			key: Date.now(),
		});
	}

	componentDidUpdate() {
		this.updateDocumentTitle();
	}

	updateDocumentTitle() {
		document.title = "用户协议";
	}
	render() {
		return (
			<div className="userAgreement">
				<div className="content">
					<p>
						脱单实验室RIGHT（以下简称“平台”）在此特别提醒您（⽤户）在使⽤本平台服务之前，请认真阅读本《脱单实验室RIGHT平台⽤户协议》（以下简称“协议”），确保您充分理解本协议中各条款，特别是涉及免除或限制责任、权利许可、信息使用的条款、法律使用和争议解决条款等，
						<strong>
							其中免除或限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读
						</strong>
						。请您审慎阅读并选择接受或不接受本协议。除⾮您接受本协议所有条款，否则您⽆权注册、登录或使⽤本协议所涉服务。您的注册、登录或使⽤等⾏为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定平台与⽤户之间关于平台微信公众号服务、⼩程序、平台H5服务（以下简称“服务”）的权利义务。“⽤户”是指注册、登录、使⽤本服务的个⼈。平台有权根据业务发展需要不时地制订、修改本协议及/或各类单⾏的规则，并以微信公众号推⽂、⼩程序、H5通知的⽅式进⾏公告，不再单独通知您。变更后的协议和规则⼀经在平台公布后，⽴即⾃动⽣效（除⾮该规则单独明确了⽣效⽇期）。若您在本协议/规则内容公告变更后继续使用平台服务的，表示为您已充分阅读、理解并接受更新后的协议/规则，也将遵守更新后的协议/规则。如果您不接受修改后的条款，请⽴即停⽌使⽤平台提供的服务。
					</p>
				</div>
				<div className="content">
					<p>
						您在使用平台某些特定产品和服务时，平台可能制定特定服务协议、业务规则等，以便更具体地向您阐明平台的服务内容、服务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
					</p>
				</div>
				<div className="title">一、相关定义</div>
				<div>
					1.脱单实验室RIGHT平台：指微信公众号脱单实验室RIGHT、服务号脱单实验室RIGHT服务号、⼩程序脱单实验室Lab、H5脱单实验室RIGHT。
				</div>
				<div>2.嘉宾：指在平台发布交友贴的⽤户。</div>
				<div>3.发帖：指嘉宾通过平台发布征友信息的⾏为。</div>
				<div>4.联系：指⽤户希望通过平台与嘉宾取得联系的⾏为。</div>
				<div>5.活动：指⽤户参与平台组织线下活动的⾏为。</div>
				<div className="title">⼆、服务内容与说明</div>
				<div>
					1、脱单实验室RIGHT的服务是指平台缘通过互联网为您提供的婚恋交友服务；具体服务以平台实际提供为准。平台有权根据实际情况修改、替换、升级提供的任何服务，并在相关页面予以告知。如果您不同意或者不接受平台的上述变更，请直接停止使用平台的服务，否则视为宁同意并接受平台提供的任何服务，同时该同意并接受的行为仍受本协议约束。
				</div>
				<div>
					2、脱单实验室RIGHT作为网络服务平台，为嘉宾提供婚恋、交友信息服务。为使用平台的服务，您应自行配备进入互联网所必需的设备（如电脑、手机等装置），并自行支付登陆互联网所需要的费用。平台提供的网络服务既包括用户通过其账号自行进行即时通讯、互动娱乐、发帖等服务也包括用户主动授权平台为其提供恋爱邀约异性的服务。
				</div>
				<div>
					3、平台提供的服务包括免费服务与收费服务。您可以通过付费方式购买收费服务。关于收费服务的具体服务内容、资费标准以平台官方相关页面公布为准。您只有完成了支付行为，才能使用相应收费服务。如您使用收费服务，需遵守相关的专项规则。
				</div>
				<div>
					4、 为提供全面、优质的婚恋交友服务、婚介服务，平台会通过发送站内信、电子邮件、短信或电话、微信通知等方式向您发送信息（内容包括但不限于服务流程确认，其他用户希望联系、约见，活动信息和咨询，新服务或新产品的推介、提示再次登录本平台、业务推广、安全提示等）； 您注册平台或实际使用平台的服务，即是明确同意接收此类信息；您如不愿意接收此类信息，您有权联系客服或通过平台提供的退订功能进行退订。如您未退订，则视为同意接收此类信息。
				</div>
				<div>
					5、对于利用平台服务进行非法活动，或其言行（无论线上或者线下的）背离平太严肃交友目的的，平台将严肃处理，采取包括但不限于警告、加入黑名单、关闭平台账号等处罚措施。
				</div>
				<div>
					<strong>
						6、平台有权对嘉宾注册资料和其他个人信息的真实性、准确性、完整性进行初步审核。用户同意平台（直接或通过第三方）进行为验证用户的身份所必须的任何查询，包括提供更多信息或文件。
					</strong>
				</div>
				<div className="title">三、注册及使⽤资格</div>
				<div>
					<strong>
						1.申请注册成为本平台⽤户应同时满⾜下列全部条件：
						<div className="child">
							a.在注册之时必须年满18周岁且具有完全⺠事⾏为能⼒；
						</div>
						<div className="child">
							b.在注册之⽇以及此后使⽤平台交友服务期间必须是单身状态，包括未婚、离异或是丧偶；在平台提供服务过程中，如果您的状态发生变更，已经不符合平台注册资格，请联系我们注销您的用户资格，否则由此产生的所有责任均由您自行承担。
						</div>
					</strong>
				</div>
				<div>
					2.您在使用平台服务之前需注册一个嘉宾账号，成为平台用户，嘉宾账号应当使用真实有效的手机号码绑定注册，并经短信验证。请您使用尚未与平台绑定的手机号码，以及未被平台根据本协议封禁的手机号码注册平台嘉宾账号。
				</div>
				<div>
					<strong>
						3、为更好的享有平台提供的服务，您同意并保证：
					</strong>
					<div className="child">
						a.您根据平台要求向平台提供本⼈真实、正确、最新及完整的资料，不得通过任何手段恶意注册平台账号（包括但不限于使用无效手机号、使用他人身份等恶意注册）；
					</div>
					<div className="child">
						b.随时更新登记资料，保持其真实性及有效性；
					</div>
					<div className="child">
						c.征友过程中，务必保持征友账号的唯⼀性。
					</div>
				</div>
				<div>3.身份认证</div>
				<div className="child">
					a.为保证发帖嘉宾的学历真实性，在必要时需要⽤户提供学⽣证或毕业证等进⾏学历认证的资料以供平台审核。
				</div>
				<div className="child">
					b.若⽤户提供任何错误、不实或不完整的资料，或平台有理由怀疑资料为错误、不实或不完整及违反⽤户注册条款的，或有辱骂、诽谤其它⽤户的，平台有权修改⽤户的注册昵称、签名、头像、个⼈详细资料页等，或暂停或终⽌该⽤户的账号，或暂停或终⽌提供平台提供的全部或部分服务。
				</div>
				<div className="title">四、账户管理</div>
				<div>
					1.平台H5账号的所有权归平台所有，⽤户完成申请注册⼿续后，获得H5账号的使⽤权，该使⽤权仅属于初始申请注册⼈，
					<strong>
						用户必须确保自己是账号的合法使用权人，并应妥善保管您的账号、密码的安全，并对该账号密码下所从事的所有行为承担责任。用户账号具有唯一性和不可转让性，
					</strong>
					禁⽌赠与、借⽤、、分享、租⽤、转让或售卖，否则，因此产生一切纠纷、损失和法律责均由用户自行承担，且平台有权根据实际情况暂时关闭或永久关闭此账号。因用户保管不善导致盗号或密码失窃，进而导致资料、信息泄漏的，由用户自行承担不利后果。当用户的账号或密码遭到未经授权的使用时或者发生其它任何安全问题时，您应当立即通知平台，否则未经授权的使用行为均视为用户本人的行为。
				</div>
				<div>
					2.⽤户应遵守本协议的各项条款，正确、适当地使⽤本服务，如因⽤户违反本协议中的任何条款，平台在通知⽤户后有权依据协议中断或终⽌对违约⽤户H5账号提供服务。同时，平台保留在任何时候收回H5账号、⽤户名的权利。
				</div>
				<div className="title">五、服务使用规则</div>
				<div>
					<strong>
						1、您在使用平台服务时，应遵守宪法法律，包括但不限于《中华人民共和国民法典》、《中华人民共和国著作权法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》等与互联网信息服务有关的法律法规；同时应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等行为。
					</strong>
				</div>
				<div>
					<strong>
						在任何情况下，平台一旦有理由认为您使用平台服务中的任何行为违反或可能违反上述法律和法规的任何规定，平台可在任何时候不经事先通知终止向该您提供服务。
					</strong>
				</div>
				<div>
					<strong>
						2、您知悉并同意：在使用平台服务时，禁止从事下列行为:
					</strong>
				</div>
				<div>
					<strong>
						1)制作、上传、复制、发布、传播或以其它方式传送含有下列信息的内容（包括但不限于照片、文字、图片、语音、视频、图文、信息或其他资料）：
					</strong>
				</div>
				<div>
					<strong>①反对宪法所确定的基本原则的；</strong>
				</div>
				<div>
					<strong>
						②危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
					</strong>
				</div>
				<div>
					<strong>
						③损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的；
					</strong>
				</div>
				<div>
					<strong>④破坏国家宗教政策，宣扬邪教和封建迷信的；</strong>
				</div>
				<div>
					<strong>⑤散布谣言，扰乱社会秩序，破坏社会稳定的；</strong>
				</div>
				<div>
					<strong>
						⑥散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
					</strong>
				</div>
				<div>
					<strong>⑦侮辱或者诽谤他人，侵害他人合法权利的；</strong>
				</div>
				<div>
					<strong>
						⑧含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它有悖道德、令人反感的内容；
					</strong>
				</div>
				<div>
					<strong>
						⑨含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的。
					</strong>
				</div>
				<div>
					<strong>2)以任何方式危害未成年人。</strong>
				</div>

				<div>
					<strong>
						3)冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或机构有关。
					</strong>
				</div>
				<div>
					<strong>
						4)将侵犯任何人的肖像权、名誉权、隐私权、专利权、商标权、著作权、商业秘密或其它专属权利的内容上载、张贴、发送电子邮件或以其它方式传送的。
					</strong>
				</div>
				<div>
					<strong>
						5)将广告函件、促销资料、"垃圾邮件"等，加以上载、张贴、发送电子邮件或以其他方式传送，供前述目的使用的专用区域除外；
					</strong>
				</div>
				<div>
					<strong>
						6)伪造标题或以其他方式操控识别资料，使人误认为该内容为平台所传送；
					</strong>
				</div>
				<div>
					<strong>
						7)跟踪或以其它方式骚扰其他您，非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的行为；
					</strong>
				</div>
				<div>
					<strong>
						8)将病毒或其它计算机代码、档案和程序，加以上载、张贴、发送电子邮件或以其它方式传送的。
					</strong>
				</div>
				<div>
					<strong>
						9)未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。
					</strong>
				</div>
				<div>
					<strong>
						10)利用平台提供的服务设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，或利用平台发布涉及实施诈骗（如花篮托、酒吧托、机票托、六合彩等），制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息；
					</strong>
				</div>
				<div>
					<strong>
						11)干扰或破坏平台服务或与平台服务相连的服务器和网络，或违反本协议干扰平台服务。
					</strong>
				</div>
				<div>
					<strong>
						12)对平台提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。
					</strong>
				</div>
				<div>
					<strong>
						13)故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范或违背严肃婚恋交友目的行为。
					</strong>
				</div>
				<div>
					<strong>
						3、您在平台平台制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的，应保证其上传内容不违反法律法规及其规范性文件，亦不侵犯任何第三方的合法权益，否则导致的一切不利后果均由您自行承担；造成平台损失的，还应向平台承担赔偿责任包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。同时：
					</strong>
				</div>
				<div>
					1）为了维护法律法规及相关规范性文件、保护他人的合法权益、维护平台的信誉及安全，平台有权直接修改、删除、下线、屏蔽您利用平台服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容，而无需事先通知您；
				</div>
				<div>
					2）平台有权视情节严重程度对该您做出警告、加黑、关闭您账号的处罚。
				</div>
				<div>
					3）平台的后台记录有可能作为您违反法律法规、违约、侵权的证据。
				</div>
				<div>
					4）除特别签订协议或者平台许可外，您利用平台服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容不得含有广告信息，包括且不限于上述的标题、简介等内容。
				</div>
				<div>
					<strong>
						4、 您应当保证，不会利用平台服务或其衍生服务侵犯平台以及他人的合法权益，禁止通过网络漏洞、恶意软件或其他手段破坏平台的正常经营，窃取、盗用他人的账号及账号下财产等；禁止侵犯他人合法权益或违反本使用规则的其他内容。否则，平台有权根据情节严重而对该您及您账号采取措施：包括但不限于发出警告、加黑、关闭账号等处罚，并无需退还该您已付的任何费用（如有）。如因此造成平台损失的，您应负责全部赔偿。构成犯罪的，平台有权移交司法部门处理。
					</strong>
				</div>
				<div>
					<strong>
						5、您在投诉其他您有违法违规行为或违反本协议情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给平台造成损失的，投诉人应承担相应的赔偿责任。
					</strong>
				</div>
				<div className="title">六、用户个人信息与隐私保护</div>
				<div>
					1、个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。您在浏览、注册、登录、使用平台的产品和服务时， 平台将按照平台公布的 《隐私保护政策》 处理和保护您的个人信息 ，因此希望您能够仔细阅读、充分理解《隐私保护政策》。
				</div>
				<div>
					2、您应当在仔细阅读、充分理解《隐私保护政策》后使用平台的产品和服务，如果您不同意政策的内容，您应立即停止访问/使用平台的产品和服务。您使用或继续使用平台提供的产品和服务的行为，都表示您充分理解和同意《隐私保护政策》（包括更新版本）的全部内容。
				</div>
				<div>
					3、平台深知个人信息对您的重要性，因此非常重视保护您的个人信息和隐私，亦将您的个人信息以高度审慎的义务对待和处理。更多关于个人信息收集、使用和保护规则、您对个人信息的管理等内容，请您查阅平台《隐私保护政策》。
				</div>
				<div>七、法律责任</div>
				<div>
					1.如果平台发现或收到他⼈举报或投诉⽤户违反本协议约定的，平台有权不经通知随时对相关内容，包括但不限于⽤户资料、应征记录进⾏审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、功能封禁的处罚，且通知⽤户处理结果。
				</div>
				<div>
					2.被封禁⽤户可向平台提交申诉，平台将对申诉进⾏审查，并⾃⾏合理判断决定是否变更处罚措施。
				</div>
				<div>
					3.⽤户理解并同意，平台有权依合理判断对违反有关法律法规或本协议规定的⾏为进⾏处罚，对违法违规的任何⽤户采取适当的法律⾏动，并依据法律法规保存有关信息向有关部⻔报告等，⽤户应承担由此⽽产⽣的⼀切法律责任。
				</div>
				<div>
					4.⽤户理解并同意，因⽤户违反法律法规或本协议约定，导致或产⽣的任何第三⽅主张的任何索赔、要求或损失，包括但不限于合理的律师费，⽤户应当赔偿平台与合作公司、关联公司，并使之免受损害。
				</div>
				<div className="title">八、免责声明</div>
				<div>
					<strong>
						1.平台不保证所提供的服务将最终帮助您找到您的伴侣,亦不保证嘉宾将会通过您的好友申请，⽆论您是否向平台⽀付了任何费⽤。{" "}
					</strong>
				</div>
				<div>
					<strong>
						2.用户理解并确认，平台的产品和服务是按照现有技术和条件所能达到的现状提供的，平台无法保证其所提供的产品和服务毫无瑕疵。本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、网络中断、用户关机、通信线路原因等造成的服务中断或不能满足用户要求和期望的风险。出现上述情况时，平台将努力在第一时间与相关单位配合，及时进行修复，但由此给用户或第三方造成的损失，平台不承担法律责任。
					</strong>
				</div>
				<div>
					<strong>
						2、用户理解并确认，用户通过平台服务所上传、分享的任何内容并不代表和反映平台的观点。对于用户通过平台上传、分享的内容，平台会尽合理努力按照国家有关规定严格审查，但无法完全控制经由平台服务传送的内容，不保证内容的正确性、完整性或品质。在使用平台服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，平台不承担法律责任。但平台有权依法停止传输前述内容并采取相应行动。
					</strong>
				</div>
				<div>
					<strong>
						3、对于通过平台相关页面展示的各种广告信息、链接、资讯等，平台会依照法律规定对广告商履行相关义务，用户知悉并同意，这些广告商和广告内容非由平台控制，用户对广告信息应审慎判断其真实性、合法性、可靠性。用户通过平台的产品和服务与广告商或广告主进行任何形式的行为或产生任何形式的纠纷，由用户自行负责与解决，除法律规定由广告发布者承担的责任外，平台不承担额外责任，但根据需要会依法提供必要的协助。
					</strong>
				</div>
				<div>
					<strong>
						4、对于用户上传的照片、资料、证件及填写的个人信息等，平台已采用相关措施并已尽合理努力进行审核，但不保证其内容的真实性、合法性或可靠性，相关责任由上传上述内容的用户承担。
					</strong>
				</div>
				<div>
					<strong>
						5、您理解并确认：用户以自己的独立判断从事与交友相关的行为，并独立承担可能产生的不利后果和责任，对非因平台原因而直接引起的用户损失，平台不承担法律责任。
					</strong>
				</div>
				<div>
					<strong>
						6、用户使用经由平台下载或取得任何资料，由用户自行考虑并自负风险；因任何资料的下载而导致的用户电脑系统的任何损坏或数据丢失等后果，平台不承担法律责任。
					</strong>
				</div>
				<div>
					<strong>
						7、平台对所有用户自发组织的活动、自发成立的组织不负法律责任。
					</strong>
				</div>
				<div>
					<strong>
						8、对于您发起的投诉，您需提供凭证辅助投诉事实，平台将尽力核实，并反馈投诉处理结果。如您对投诉处理结果仍有异议，可继续发起申诉。
					</strong>
				</div>
				<div>
					<strong>
						9、尽管平台已采取相应的技术保障措施，但用户仍有可能收到各类的广告信（平台发送的广告信除外）或其他不以交友为目的邮件，平台不承担法律责任。
					</strong>
				</div>
				<div>
					<strong>
						10、除平台官方公布的方式外，用户通过其他未经过平台合法授权的渠道、途径、方式获取的平台的产品与服务（包括且不限于账号）均为非法取得，平台概不承认其效力，且一经发现平台有权立即做出删除、取消、清零、封号等处理，因此导致的一切不利后果均由用户自行承担。
					</strong>
				</div>
				<div>
					<strong>
						11、用户因缺少身份认证或认证信息不真实而导致账号、账号内财产等丢失、减少而无法找回的，平台不承担法律责任。
					</strong>
				</div>
				<div>
					<strong>
						12、平台有权独立判断用户的行为是否违反中国法律法规或本协议约定或违背严肃婚恋交友目的；如存在上述任一行为，平台有权视情节轻重对违规账号处以包括但不限于警告、加黑、关闭账号等处罚；并有权依法保存有关信息并向主管部门报告。该权利不构成平台的义务或承诺，平台不能保证及时发现违法行为或进行相应处理。
					</strong>
				</div>
				<div>
					<strong>
						13、除法律明确规定外，平台对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有法律责任。
					</strong>
				</div>
				<div>
					<strong>
						14、您理解并同意，平台不对因下述情况而导致您的损害赔偿承担责任：
					</strong>
				</div>
				<div>
					<strong>(1)因您原因未能使用平台服务。</strong>
				</div>
				<div>
					<strong>
						(2)第三方未经批准的使用您的账号或更改您的数据。
					</strong>
				</div>
				<div>
					<strong>(3)您对平台服务的误解。</strong>
				</div>
				<div>
					<strong>
						(4)非因平台的原因而引起的与平台服务有关的其它损失。
					</strong>
				</div>
				<div>
					<strong>
						(5)因您进行上传或张贴，而导致第三方提出侵权或索赔要求的，您承担全部责任；平台仅承担权利方告知后的配合删除屏蔽等法定义务。
					</strong>
				</div>
				<div>
					<strong>
						(6)第三方对于您在平台的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与 平台无关。
					</strong>
				</div>
				<div>
					15.您清楚认知到⾃⼰同意公开发布在平台上的信息可能会被任何第三⽅知晓、转载或被扩
					散，若因此给您带来不便甚⾄造成损失，平台不承担任何法律责任。
				</div>
				<div>
					16.您清楚认知到在嘉宾与⽤户沟通交流的环节中，双⽅都可以获知对⽅进⼀步的个⼈信息，平台并⽆义务也⽆能⼒监管任何⼀⽅的个⼈⾏为，也不对其可能造成的任何不利后果或损害承担任何法律责任。
				</div>
				<div className="title">九、知识产权</div>
				<div>
					1、平台尊重知识产权并注重保护用户享有的各项权利。用户在平台服务中上载或发布内容（包括但不限于个人照片、肖像、文字等）或授权平台的行为不会对用户就上述内容所享有的知识产权产生不良影响，上载或发布的作品的著作权仍归作品的创作者所有。
					<strong>
						 为了更好地对用户、用户上载或发布的内容以及平台交友宣传推广，用户同意在全世界范围内，免费、非独家授予平台对该信息行使发布、使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；通过有线或无线网络向用户的计算机终端、移动通讯终端等提供信息的下载、点播、数据传输及相关的宣传和推广等服务的权利。该授权持续至用户书面通知平台不得继续使用，且平台实际收到该等书面通知时止。
					</strong>
				</div>
				<div>
					2、除本服务中涉及广告的知识产权由相应广告商享有外，平台在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归平台所有。
				</div>
				<div>
					3、平台提供本服务时所依托软件的著作权、专利权及其他知识产权均归平台所有。平台在本服务中所涉及的图形、文字或其组成，以及其他平台标志及产品、服务名称，其著作权或商标权归平台所有。
				</div>
				<div>
					<strong>
						4、上述及其他任何平台或相关广告商依法拥有的知识产权均受到法律保护，未经平台或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，平台将依法追究违约方的法律责任。
					</strong>
				</div>
				<div className="title">九、服务的变更、中断、终止</div>
				<div>
					1.鉴于⽹络服务的特殊性，⽤户同意平台有权随时变更、中断或终⽌部分或全部的服务（包括收费服务）。平台变更、中断或终⽌的服务，平台有权在变更、中断或终⽌之前通知⽤户，
					并无须向您负责或承担任何赔偿责任。
				</div>
				<div>
					2.如发⽣下列任何⼀种情形，平台有权变更、中断或终⽌向⽤户提供的免费服务或收费服务，⽽⽆需对⽤户或任何第三⽅承担任何责任：
				</div>
				<div className="child">
					a.根据法律规定或本协议要求⽤户应提交真实信息，⽽⽤户提供的个⼈资料不真实、或与注册时信息不⼀致未能提供合理证明；
				</div>
				<div className="child">
					b.本协议（含规则）变更时，您明示并通知平台不接受新的协议的；
				</div>
				<div className="child">
					c.⽤户违反相关法律法规或本协议的约定；
				</div>
				<div className="child">d.按照法律规定或有权机关的要求；</div>
				<div className="child">e.出于安全的原因或其他必要的情形。</div>
				<div className="title">十、其他</div>
				<div>
					1.本协议的效⼒、解释及纠纷的解决，适⽤于中华⼈⺠共和国法律。若⽤户和平台之间发⽣
					任何纠纷或争议，⾸先应友好协商解决，协商不成的，⽤户同意将纠纷或争议提交江苏省南京
					市⾬花台区⼈⺠法院管辖。
				</div>
				<div>
					2.本协议的任何条款⽆论因何种原因⽆效或不具可执⾏性，其余条款仍有效，对双⽅具有约
					束⼒。
				</div>
				<div>
					3.由于互联⽹⾼速发展，您与平台签署的本协议列明的条款可能并不能完整罗列并覆盖您与
					平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，平台隐私权政
					策、平台使⽤规则等不时发布的单项政策、规则均为本协议的补充协议，与本协议不可分割且
					具有同等法律效⼒。如您使⽤本平台服务，视为您同意上述补充协议。
				</div>
			</div>
		);
	}
}

export default withRouter(UserAgreement);
