import React, { Component } from 'react';
import { RedFeeTypeList, RedFreeConfigList } from '@store/models/detail/types';
import {
	Button, Container, Grid
} from '@material-ui/core';
import './index.scss';
import { WeChatConfig } from '@store/models/wechat/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { getCustomPrice, getUserInfoByCode, redCustomPayment } from '@service/api';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';
import jsonrpc from '@libs/jsonrpc';
import { ApplicationState } from '@store';
import { bindActionCreators, Dispatch } from 'redux';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as UserDetailDataActions from '@store/models/detail/actions';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { Skeleton } from '@material-ui/lab';

interface State {
	priceType: string;
	customerId: string;
	redFeeTypeList: any;
}

interface StateProps {
	wechatConfig: WeChatConfig;
	redFeeTypeList: RedFeeTypeList;
	redFreeConfigList: RedFreeConfigList;
}

interface DispatchProps {
	loadWeChatRequest(): void;

	loadGetRedFeeTypeRequest(): void;

	loadGetRedFeeConfigRequest(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class Activity extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			redFeeTypeList: {
				"red_one": {
					"price": 9999,
					"count": 1,
					"send_member": false
				},
				"red_three": {
					"price": 9999,
					"count": 3,
					"send_member": false
				}
			},
			priceType: 'red_three',
			customerId: ''
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		this.setShare();
		const {
			loadWeChatRequest,
			loadGetRedFeeTypeRequest,
			loadGetRedFeeConfigRequest
		} = this.props;
		const { match } = this.props;
		const params: any = match.params;
		if(!params.id) {
			Toast.default({
				title: '链接错误，请联系红娘'
			});
		}
		this.updateDocumentTitle();
		const param: any = querystring.parse(window.location.href.split('?')[1]);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			await getUserInfoByCode(param.code);
			window.location.replace(window.location.pathname);
		}
		loadWeChatRequest();
		loadGetRedFeeTypeRequest();
		loadGetRedFeeConfigRequest();
		try {
			let content = await getCustomPrice(params.id);
			this.setState({
				redFeeTypeList: content,
				customerId:params.id
			})
		} catch (error) {
			console.error(error)
		}
	}

	componentDidUpdate() {
		const { wechatConfig } = this.props;
		this.updateDocumentTitle();
		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res: any) => {
				console.log(res);
			});
		}
	}

	updateDocumentTitle() {
		document.title = '脱单实验室'
	}

	changePriceType(priceType: string): void {
		this.setState({
			priceType
		});
	}


    setRedPayment(): Promise<any> {
		const {
			priceType,
			customerId
		} = this.state;
		let timer: any = null;
		const feeType: string = priceType;
		return redCustomPayment(feeType, customerId)
			.then((res: any) => {
				if (res.status === '购买成功') {
					Toast.default({
						title: '购买成功'
					});
					return;
				}
				window.wx.ready(() => {
					window.wx.chooseWXPay({
						timestamp: res.timeStamp,
						nonceStr: res.nonceStr,
						package: res.package,
						signType: res.signType,
						paySign: res.paySign,
						success: (result: any) => {
							Toast.show({
								title: '支付中，请稍等……'
							});

							timer = setInterval(() => {
								jsonrpc.blind.h5.getPayStatusByTradeNo(res.out_trade_no)
									.then((resultStatus: any) => {
										if (resultStatus.status === '支付成功') {
											if (timer) {
												clearInterval(timer);
											}
											Toast.hide({
												callback: () => {
													Toast.default({
														title: '购买成功'
													});
												}
											});
										}
									})
									.catch((err: any) => {
										if (timer) {
											clearInterval(timer);
										}
										Toast.hide({
											callback: () => {
												Toast.default({
													title: err.msg
												});
											}
										});
									});
							}, 1000);
						},
						fail: (err: any) => {
							Toast.hide({
								callback: () => {
									Toast.default({
										title: '微信支付失败'
									});
								}
							});
						}
					});
				});
			})
			.catch((err: any) => {
				Toast.hide({
					callback: () => {
						Toast.default({
							title: err.msg
						});
					}
				});
			});
	}
	setShare(): void {
		const url = window.location.origin + window.location.pathname;
		window.wx.ready(() => {
			window.wx.updateAppMessageShareData({
				title: '高端1v1精准匹配，全新脱单模式闪亮登场',
				desc: '优质会员真实靠谱，资深红娘全程服务，精准高效速配脱单，数据分析深度认知，安全放心高度保密！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind-date/activity.png'
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '高端1V1精准匹配服务，最高效的脱单方式！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind-date/activity.png'
			});
		});
	}
	render() {
		const {
			redFreeConfigList
		} = this.props;
		const { priceType,redFeeTypeList } = this.state;
		return (
			<Container className="custom_price" disableGutters>
                <div className="top-card-wrap">
                    <div className="bg"></div>
                    {/* <img src={require('@images/activity.png')} /> */}
				</div>
                <div className="details">
                    <div className="qrcode-box">
                        {
                            redFreeConfigList.detail_qrcode
                                ? <img src={redFreeConfigList.detail_qrcode} alt="" />
                                : <Skeleton animation="wave" variant="rect" width={112} height={112} />
                        }
                        <div>服务详情请添加红娘微信</div>
                    </div>
                    <div className="activityPrice">
                        <div className={`priceContainer ${priceType === 'red_one' && 'active'}`} onClick={() => this.changePriceType('red_one')}>
							{redFeeTypeList.red_one.send_member ? <img className='activityIcon' src={require('@images/activityIcon.png')} alt="" /> : ""}
							<div className='price'>
								<span>
									<span className='symbol'>¥</span>
									{redFeeTypeList.red_one.price}
								</span>
							</div>
                            <div className='content'>推荐三位</div>
                        </div>
                        <div className={`priceContainer ${priceType === 'red_three' && 'active'}`} onClick={() => this.changePriceType('red_three')}>
							{redFeeTypeList.red_three.send_member ? <img className='activityIcon' src={require('@images/activityIcon.png')} alt="" /> : ""}
                            <div className='price'>
								<span>
									<span className='symbol'>￥</span>
									{redFeeTypeList.red_three.price}
								</span>
							</div>
                            <div className='content'>推荐六位</div>
                        </div>
                    </div>
                    <div className={`footer active`} onClick={() => this.setRedPayment()}>
                    	<div className='bug'>立即购买</div>
                    </div>
                </div>
				
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	redFeeTypeList: state.redFeeTypeList.data,
	redFreeConfigList: state.redFreeConfigList.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions,
	...UserDetailDataActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
